import React from 'react'
import Landing from './Landing'
import WhyUs from './WhyUs'
import Hocl from './Hocl'
import Footer from './Footer'
import Results from './Results'

const Home = () => {
  return (
    <>
    <Landing />
    <WhyUs />
    <Hocl />
    <Results />
    <Footer />
    </>
  )
}

export default Home
