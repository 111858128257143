import React, { useEffect, useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import Aos from "aos";
import "aos/dist/aos.css";
import { HashLink as Link } from "react-router-hash-link";


const Nav = () => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    Aos.init();
  });

  return (
    <>
    <nav id='home'  data-aos="fade-down"
                data-aos-duration="1200"
                data-aos-delay="1200">
      <div className="nav__row">
        <div className="nav__items"  >
          <div className="nav__menu">
            <IoMenu onClick={() => setOpenMenu(true)} />
          </div>
          <div className="nav__logo">
           <h1><a href="#home" className="nav__logo--link">Skin Cleanse</a></h1>
          </div>
          <div className="nav__cta">
            <h1>Buy Now</h1>
          </div>
        </div>
      
      </div>
    </nav>
    {openMenu ? (
          <div
            className="nav__sidebar"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <ul>
              <li><a href="#home" onClick={() => setOpenMenu(false)}>Home</a></li>
              <li><a href="#whyus" onClick={() => setOpenMenu(false)}>Why Us</a></li>
              <li><a href="#hocl" onClick={() => setOpenMenu(false)}>Hypochlorous Acid</a></li>
              <li><a href="#results" onClick={() => setOpenMenu(false)}>Results</a></li>
            </ul>
            <div className="sidebar__close">
              <IoClose onClick={() => setOpenMenu(false)} />
            </div>
          </div>
        ) : null}
    </>
  );
};

export default Nav;
