import React, { useEffect } from "react";
import LandingImg from "../assets/woman.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const Landing = () => {
  useEffect(() => {
    Aos.init();
  });

  return (
    <section id="landing">
      <div className="container landing">
        <div className="row">
          <div className="landing__container">
            <div className="landing__info" data-aos="fade-up" data-aos-duration="1500">
              <h1 >
                Your Ultimate <span className="blue">Skincare Solution</span>
              </h1>
              <p
             
              >
                Experience the power of Skin Cleanse, crafted with care and
                innovation to revolutionize your skincare routine. Skin Cleanse
                offers a gentle yet powerful way to achieve radiant,
                healthy-looking skin.
              </p>
            </div>
            <div className="landing__img--container">
              <img
                src={LandingImg}
                alt=""
                className="landing__img"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="1000"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
