
import Home from './components/Home';
import Nav from './components/Nav';
import './index.css';

function App() {
  return (
   <>
    <Nav />
    <Home />
   </>
  );
}

export default App;
