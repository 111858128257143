import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Results1Img from '../assets/results1.jpg'
import Results2Img from '../assets/results2.jpg'
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import Aos from "aos";
import "aos/dist/aos.css";

const Results = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow({ onClick }) {
    return (
      <div className="results__arrow-next" onClick={onClick}>
        <HiChevronRight />
      </div>
    );
  }

  function SamplePrevArrow({ onClick }) {
    return (
      <div className="results__arrow-prev" onClick={onClick}>
        <HiChevronLeft />
      </div>
    );
  }

  useEffect(() => {
    Aos.init();
  });

  return (
    <section id="results">
    <div className="container results">
      <div className="row">
        <div className="results__container" data-aos="fade-in"
                data-aos-duration="1500"
               >
          <div className="results__title">
            <h1>Results</h1>
          </div>
          <div className="results__slider">
            <Slider {...settings}>
              <div><img src={Results1Img} alt="" className="results__img"/></div>
              <div><img src={Results2Img} alt="" className="results__img"/></div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Results;
