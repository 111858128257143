import React, { useEffect } from "react";
import HoclImg from '../assets/undraw1.svg';
import Aos from "aos";
import "aos/dist/aos.css";

const Hocl = () => {

    useEffect(() => {
        Aos.init();
      });

  return (
    <section id="hocl">
    <div className="container hocl">
      <div className="row">
        <div className="hocl__container">
          <div className="hocl__img--container" >
            <img className='hocl__img' src={HoclImg} alt="" data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="1400"/>
          </div>
          <div className="hocl__info">
            {/* <h2 data-aos="fade-in"
                data-aos-duration="1000"
               >HOCL</h2> */}
            <h1 data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="800">
              The Power of <span className="blue">Hypochlorous Acid</span>
            </h1>
            <p data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="1000">
              Discover the transformative benefits of Hypochlorous Acid for your
              skin. Unlike traditional skincare products, Hypochlorous Acid is a
              gentle yet potent solution that effectively cleanses, soothes, and
              rejuvenates without harsh chemicals or irritation. It naturally
              balances pH levels, making it suitable for all skin types,
              including sensitive and acne-prone skin. Its antimicrobial
              properties help combat acne-causing bacteria, reduce inflammation,
              and promote faster healing of minor skin irritations. Incorporate
              our Hypochlorous Acid skincare products into your daily regimen to
              experience clearer, smoother skin and a radiant complexion, backed
              by science and trusted by dermatologists worldwide. Elevate your
              skincare routine with the power of Hypochlorous Acid today.
            </p>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Hocl;
