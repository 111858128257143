import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const WhyUs = () => {

    useEffect(() => {
        Aos.init();
      });

  return (
    <section id="whyus">
    <div className="container why">
      <div className="row">
        <div className="why__container">
          <div className="why__title">
            <h2  data-aos="fade-in"
                data-aos-duration="1000">WHY US</h2>
            <h1  data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="500">
              Choose Us for <span className="blue">Superior Hygiene</span>
            </h1>
          </div>
          <div className="why__items">
            <div className="why__item"  data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="800">
              <h1>Effective Cleansing</h1>
              <p>
                Cleanses deeply without harsh chemicals, ensuring your skin
                feels fresh and rejuvenated for a vibrant, healthy look.
              </p>
            </div>
            <div className="why__item" data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="800">
              <h1>Suitable for All Skin Types</h1>
              <p>
                Whether you have sensitive, oily, or combination skin, Skin
                Cleanse is formulated to meet your unique skincare needs.
              </p>
            </div>
            <div className="why__item" data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="800">
              <h1>Gentle on Your Skin</h1>
              <p>
                Designed to be gentle and soothing, Skin Cleanse provides a
                mild, effective cleanse without causing irritation or
                discomfort.
              </p>
            </div>
            <div className="why__item" data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="1200">
              <h1>Dermatologist Recommended</h1>
              <p>
                Recommended by dermatologists for its safety and effectiveness,
                this product integrates seamlessly into daily skincare routines.
              </p>
            </div>
            <div className="why__item" data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="1200">
              <h1>Quick and Easy Application</h1>
              <p>
                Effortlessly fit skincare into your day with a quick spray. This
                product is designed for busy lifestyles, making your routine
                seamless and effective.
              </p>
            </div>
            <div className="why__item" data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="1200">
              <h1>Balances Your Skin's pH</h1>
              <p>
                Supports your skin's natural pH balance, helping to keep your
                complexion harmonious and healthy while preventing dryness and
                irritation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default WhyUs;
