import React from 'react'

const Footer = () => {
  return (
    <div className="container footer">
        <div className="row">
            <div className="footer__container">
                <div className="footer__logo">
                <h1><a href="#home">Skin Cleanse</a></h1>
                </div>
                <div className="footer__links">
                    <ul>
                    <li>
                    <a href="#whyus">Why Us</a>
                        </li>
                        <li>
                        <a href="#hocl">HOCL</a>
                        </li>
                        <li>
                        <a href="#results">Results</a>
                        </li>
                    </ul>
                </div>
                <div className="footer__copyright">
                    <h1>Skin Cleanse © 2024 </h1>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
